/* eslint-disable prettier/prettier */
import rtl from 'jss-rtl';
import { create } from 'jss';
import palette from './palette';
import shadows from './shadows';
import PropTypes from 'prop-types';
import typography from './typography';
import breakpoints from './breakpoints';
import createCache from '@emotion/cache';
import rtlPlugin from 'stylis-plugin-rtl';
import GlobalStyles from './globalStyles';
import borderRadius from './borderRadius';
import componentsOverride from './overrides';
import { CacheProvider } from '@emotion/react';
import useSettings from 'src/hooks/useSettings';
import React, { useMemo, useEffect, useState } from 'react';
import {
  jssPreset,
  ThemeProvider,
  createMuiTheme,
  StylesProvider
} from '@material-ui/core/styles';
import { CssBaseline } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { getCompanyInfo, getCompanyLoader } from 'src/redux/slices/settings';

// ----------------------------------------------------------------------

RTLProvider.propTypes = {
  direction: PropTypes.oneOf(['ltr', 'rtl']),
  children: PropTypes.node
};

function RTLProvider({ direction, children }) {
  const isRTL = direction === 'rtl';
  const jss = create({
    plugins: [...jssPreset().plugins, rtl()]
  });

  const cache = createCache({
    key: isRTL ? 'rtl' : 'css',
    prepend: true,
    stylisPlugins: isRTL ? [rtlPlugin] : []
  });

  cache.compat = true;

  useEffect(() => {
    document.dir = direction;
  }, [direction]);

  return (
    <CacheProvider value={cache}>
      <StylesProvider jss={jss}>{children}</StylesProvider>
    </CacheProvider>
  );
}

ThemeConfig.propTypes = {
  children: PropTypes.node
};

function ThemeConfig({ children }) {
  const dispatch = useDispatch();
  const { themeMode, themeDirection } = useSettings();
  const { customTheme, companyInfoRes, companyLoaderRes } = useSelector(
    (state) => state.settings
  );
  const [paletteConfig, setPalettConfig] = useState({
    primaryColor: '',
    secondaryColor: ''
  });

  useEffect(() => {
    setPalettConfig(customTheme);
    // console.log('customTheme:', customTheme);
  }, [customTheme]);

  // console.log('paletteConfig:', paletteConfig);

  const isLight = themeMode === 'light';

  // console.log('customTheme:', customTheme);

  const themeOptions = useMemo(
    () => ({
      palette: {
        ...palette[isLight ? 'light' : 'dark'],
        primary: {
          lighter: '#C8FACD', //used
          light: '#5BE584', // used
          main: paletteConfig?.primaryColor
            ? paletteConfig?.primaryColor
            : '#00AB55',
          dark: paletteConfig?.secondaryColor
            ? paletteConfig?.secondaryColor === '#FFFFFF'
              ? paletteConfig?.primaryColor
              : paletteConfig?.secondaryColor
            : '#007B55'
        }
      },
      shadows: shadows[isLight ? 'light' : 'dark'],
      typography: typography,
      shape: borderRadius,
      breakpoints: breakpoints,
      direction: themeDirection,
      components: componentsOverride({
        theme: {
          // palette: palette[isLight ? 'light' : 'dark'],
          palette: {
            ...palette[isLight ? 'light' : 'dark'],
            primary: {
              lighter: '#C8FACD', //used
              light: '#5BE584', // used
              main: paletteConfig?.primaryColor
                ? paletteConfig?.primaryColor
                : '#00AB55',
              dark: paletteConfig?.secondaryColor
                ? paletteConfig?.secondaryColor === '#FFFFFF'
                  ? paletteConfig?.primaryColor
                  : paletteConfig?.secondaryColor
                : '#007B55'
            }
          },
          shadows: shadows[isLight ? 'light' : 'dark'],
          typography: typography,
          shape: borderRadius,
          direction: themeDirection
        }
      })
    }),
    [isLight, themeDirection, customTheme, paletteConfig]
  );

  const theme = createMuiTheme(themeOptions);

  useEffect(() => {
    if (window.location.pathname === '/auth/manualLogin' || window.location.pathname === '/auth/corporateLogin') {
      setPalettConfig({
        primaryColor: '',
        secondaryColor: ''
      });

      dispatch(getCompanyLoader(true));
      dispatch(getCompanyInfo({ domainName: window.location.hostname }));
      // dispatch(getCompanyInfo({ domainName: 'rjl.limor.us' }));
    }
    // console.log("PalleteElse:1", window.location.pathname);
  }, []);

  useEffect(() => {
    if (companyLoaderRes) {
      dispatch(getCompanyLoader(false));
      if (companyInfoRes && companyInfoRes?.status === 1) {

        // console.log("companyInfoRes", companyInfoRes);

        localStorage.setItem('disableMenus', companyInfoRes.responseData.disableMenus);

        // console.log("company_id", localStorage.getItem('company_id'));

        if (companyInfoRes?.responseData) {
          // Theme color
          setPalettConfig({
            primaryColor: companyInfoRes?.responseData?.primaryColorCode,
            secondaryColor: companyInfoRes?.responseData?.secondaryColorCode
          });

          // FavIcon
          let link = document.querySelector("link[rel~='icon']");
          if (!link) {
            link = document.createElement('link');
            link.rel = 'icon';
            document.getElementsByTagName('head')[0].appendChild(link);
          }
          link.href = companyInfoRes?.responseData?.favicon;
        }
      } else {
        setPalettConfig({
          primaryColor: '',
          secondaryColor: ''
        });

        // FavIcon
        let link = document.querySelector("link[rel~='icon']");
        if (!link) {
          link = document.createElement('link');
          link.rel = 'icon';
          document.getElementsByTagName('head')[0].appendChild(link);
        }
        link.href = '/favicon/favicon-16x16.png';

        // console.log('PalleteElse:2');
      }
    }
  }, [companyInfoRes]);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <GlobalStyles />
      <RTLProvider direction={themeDirection}>{children}</RTLProvider>
    </ThemeProvider>
  );
}

export default ThemeConfig;
